<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4> Teachers</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard</router-link>
                    \ Teachers
                  </div>
                </div>

              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <v-skeleton-loader
                    v-if="loading"
                    type="table-thead"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
                <table class="table" v-if="!loading">
                  <thead>
                    <tr class="px-3">
                      <th class="px-3">Full name</th>
                      <th>Email</th>
                      <th>Course</th>
                      <th>Program</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-show="teachers.length > 0"  v-for="(item,index) in teachers " :key="index">
                      <td class="px-3">
                        <span class="font-weight-medium">
                          {{item.full_name }}
                        </span>
                      </td>
                      <td>
                        <a class="text-info mb-1" :href="'mailto:'+item.email">
                          {{item.email ? item.email : 'NA' }}
                        </a>
                      </td>
                      <td>
                        <v-chip small color="purple" text-color="white" v-if="item.courses">
                          {{item.courses}}
                        </v-chip>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <div v-show="item && item.programs && item.programs.length > 0" v-for="(program,index) in item.programs" :key="index">
                          <v-chip small color="blue" text-color="white"  class="mt-2 ml-2">
                            {{program}}<br>
                          </v-chip>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="teachers.length==0">
                      <td colspan="4" class="text-center">
                        No items found.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app>
</template>

<script>
    import StudentSettingService from "@/core/services/user/student/StudentSettingService";
    const studentSettingService = new StudentSettingService();
    export default {
        name: "OnlineClass",
        data() {
            return {
                loading: true,
                teachers: [],
            }
        },
        mounted() {
            this.getTeachers()
        },
        methods: {
            getTeachers() {
                this.loading = true;
                studentSettingService.getTeachers().then(response => {
                    this.teachers = response.data.teachers;

                }).finally(()=>{
                    this.loading = false;
                });
            },
            __get() {
                this.loading = true
                onlineClassRoomService.paginate().then(response => {
                  this.items = response.data.online_classes;
                }).catch(err => {

                }).finally(() => {
                    this.loading = false;
                })
            }
        },
    }
</script>

<style scoped>

</style>
